<template lang="pug">
.chart-container(v-if="seriesData.length > 0")
    chart(:options="flowChartOptions")
</template>

<script lang="js">

import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';

export default {
    name: 'FlowChart',
    components: {
        chart: Chart
    },
    props: {
        // every series for the chart
        seriesData: {
            type: Array,
            required: false,
            default: () => []
        },
        productionTime: {
            type: Number,
            required: false,
            default: null
        },
        productionVolume: {
            type: Number,
            required: false,
            default: null
        },
        startDate: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: false,
        },
        isHistorical: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        const highchartsColors = Highcharts.getOptions().colors;
        return {
            flowChartOptions: {
                credits: { enabled: false },
                colors: ['#058DC7', '#50B432', '#ED561B', '#754087', '#24CBE5', '#64E572'],
                chart: {
                    type: 'line',
                    backgroundColor: '#f5f5f5', // Light gray for subtle contrast
                    plotBorderWidth: 1,
                    plotBorderColor: '#E0E0E0', // Faint border for definition
                    shadow: {
                        color: 'rgba(0, 0, 0, 0.2)',  // Color of the shadow
                        offsetX: 3,                  // Horizontal offset of the shadow
                        offsetY: 3,                  // Vertical offset of the shadow
                        opacity: 0.5,                // Opacity of the shadow
                        width: 5                     // Blur width of the shadow
                    },
                    spacing: [30, 30, 30, 30],
                    borderRadius: 10,
                    borderWidth: 1,                 // Width of the border in pixels
                    borderColor: '#d1d1d1'
                },
                title: {
                    text: '',
                    align: 'left'
                },
                yAxis: [
                    {
                        title: {
                            text: 'GPH '
                        }
                    },
                    {
                        title: {
                            text: 'Gallons'
                        },
                        opposite: true
                    },
                ],
                xAxis: {
                    categories: Array.from({ length: 24 }, (_, i) => i),
                    labels: {
                        formatter: function () {
                            let hour = this.value;
                            let amPm = hour < 12 ? 'AM' : 'PM';
                            hour = hour % 12;
                            hour = hour || 12;
                            return `${hour} ${amPm}`;
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                    backgroundColor: '#ffffff', // White background for better contrast
                    borderColor: '#cccccc',
                    borderRadius: 10,
                    shadow: true,
                    style: {
                        color: '#333333',
                        fontSize: '12px'
                    },
                    formatter: function () {
                        let hour = this.x;
                        let amPm = hour < 12 ? 'AM' : 'PM';
                        hour = hour % 12;
                        hour = hour || 12;
                        let s = `<h3 style="margin: 2px 0;">Time: ${hour} ${amPm}</h3><br>`;
                        s += '<table style="width:100%; text-align: left;">';
                        this.points.forEach(function (point) {
                            let seriesName = point.series.name
                                .toLowerCase()
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                                .trim();

                            s += `<tr>` +
                                `<td>` +
                                    `<span style="color:${point.color}; font-size: 14px;"><strong>${seriesName}</strong></span>` +
                                `</td>` +
                                `<td style="text-align: right; padding-left: 10px;">` +
                                    `<b>${point.y}</b>` +
                                `</td>` +
                            `</tr>`;
                        });
                        s += '</table>';
                        return s;
                    }
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                    backgroundColor: '#ffffff',
                    borderRadius: 5,
                    shadow: true,
                    labelFormatter: function () {
                        // Convert the series name to Title Case
                        return this.name
                            .toLowerCase()
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')
                            .trim();
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        lineWidth: 1.5,
                        animation: {
                            duration: 1500,
                            easing: 'easeOutBounce'
                        },
                        states: {
                            hover: {
                                lineWidthPlus: 2.5
                            }
                        }
                    }
                },
                series: []
            }
        };
    },
    watch: {
        seriesData: {
            handler (newVal, oldVal) {
                this.$set(this.flowChartOptions, 'series', newVal);
            },
            deep: true
        },
        productionTime () {
            this.updateSubtitle();
        },
        productionVolume () {
            this.updateSubtitle();
        },
        startDate () {
            this.updateSubtitle();
        }
    },
    methods: {
        init () {
            const titleAddendum = this.isHistorical ? '(actual)' : '(prediction)';
            this.flowChartOptions.title.text = `${this.name} ${titleAddendum}`;
            if (this.seriesData?.length > 0) {
                this.$set(this.flowChartOptions, 'series', this.seriesData);
            } else {
                this.$set(this.flowChartOptions, 'series', []);
            }
            this.updateSubtitle();
        },
        updateSubtitle () {
            let subtitleText = this.startDate ? `Date: ${this.startDate}` : '';
            if (this.productionTime != null || this.productionVolume != null) {
                subtitleText += `<br>Production Time: <span style="font-weight: bolder;">${this.productionTime || 0} hrs</span>, Production Volume: <span style="font-weight:bolder;">${this.productionVolume || 0} gals</span>`;
            }
            this.flowChartOptions.subtitle = {
                text: subtitleText,
                align: 'left'
            };
        }
    },
    mounted () {
        this.init();
    },
};

</script>
